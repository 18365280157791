import React from "react";
import { Container, Col, Row, Image } from "react-bootstrap";
import authBanner from "../../asset/images/auth-banner.png";

const AuthComponent = ({ children }) => {
  return (
    <div className="auth-page">
      <Container fluid>
        <Row className="align-items-center">
          <Col md={7} className="d-flex justify-content-center">
            <Image fluid src={authBanner} alt="Banner" />
          </Col>
          <Col md={5}>{children}</Col>
        </Row>
      </Container>
    </div>
  );
};

export default AuthComponent;
