import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import student from "../asset/images/student.jpeg";
import { Row } from "reactstrap";
import { Image } from "react-bootstrap";

const Header = () => {
  return (
    <Row className="header-container align-items-center" fluid>
      <FontAwesomeIcon icon={faBell} />
      <Image fluid src={student} alt="" />
    </Row>
  );
};

export default Header;
