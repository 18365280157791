import React from "react";
// import { connect } from "react-redux";
import {
  // BrowserRouter,
  // Redirect,
  Route,
  // Switch,
  // withRouter,
} from "react-router-dom";
import Layout from "../../hoc/Layout/Layout";
import Students from "./Students";
const Dashboard = () => {
  let dashboardRoutes = (
    <>
      <Route path="/dashboard/students" component={Students} />
      {/* <Route path="/dashboard/groups" component={Test} /> */}
    </>
  );
  return (
    <div>
      <Layout>{dashboardRoutes}</Layout>
    </div>
  );
};

export default Dashboard;
