import { useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import SubMenu from "./SubMenu";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
// import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import StudentDetails from "./StudentDetails";
import Header from "./Header";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserCheck,
  faUserCircle,
  faBook,
  faCalendarAlt,
  faEdit,
  faCoins,
  faBoxes,
  faBars
} from "@fortawesome/free-solid-svg-icons";
import Footer from "./Footer";

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - 250px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: "250px",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const Sidebar = ({ children }) => {
  const [showSubMenu, setShowSubMenu] = useState(null);
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const menuList = [
    {
      item: "Profile",
      icon: faUserCircle,
      path: "/profile",
      children: [],
    },
    {
      item: "Attendance Info",
      icon: faUserCheck,
      path: "/attendance-info",
      children: [],
    },
    {
      item: "Subject Info",
      icon: faBook,
      path: "/subject-info",
      children: [],
    },
    {
      item: "Routine Info",
      icon: faCalendarAlt,
      path: "#",
      children: [
        {
          item: "Class Routine",
          icon: faUserCheck,
          path: "/routine-info/class-routine",
          children: [],
        },
        {
          item: "Exam Routine",
          icon: faUserCheck,
          path: "/routine-info/exam-routine",
          children: [],
        },
      ],
    },
    {
      item: "Exam Info",
      icon: faEdit,
      path: "#",
      children: [
        {
          item: "Semester Exam",
          icon: faUserCheck,
          path: "/exam-info/semester-exam",
          children: [],
        },
        {
          item: "Class Test",
          icon: faUserCheck,
          path: "/exam-info/class-test",
          children: [],
        },
      ],
    },
    {
      item: "Fees Info",
      icon: faCoins,
      path: "/fees-info",
      children: [],
    },
    {
      item: "Inventory Info",
      icon: faBoxes,
      path: "/inventory-info",
      children: [],
    },
  ];

  const url = history.location.pathname.split("/");
  console.log(url);

  // useEffect(() => {
  //   if(url[1] === "routine-info"){
  //     showSubMenu !== "Routine Info" && setShowSubMenu("Routine Info");
  //   }
  //   if(url[1] === "exam-info"){
  //     showSubMenu !== "Exam Info" && setShowSubMenu("Exam Info");
  //   }
  // }, [])
  

  const drawer = (
    <div className="side-bar">
      <div className="logo-container d-flex py-3 justify-content-center align-items-center">
        <h1>
          <Link to="/">Student Portal</Link>
        </h1>
      </div>
      {/* <Divider /> */}
      <List>
        {menuList.map(({ item, icon, children, path }) =>
          children.length === 0 ? (
            <NavLink to={path} activeClassName="nav-active">
              <ListItem button key={item}>
                <ListItemIcon>
                  <FontAwesomeIcon icon={icon} />
                </ListItemIcon>
                <ListItemText primary={item} />
              </ListItem>
            </NavLink>
          ) : (
            <SubMenu
              showSubMenu={showSubMenu}
              setShowSubMenu={setShowSubMenu}
              item={item}
              children={children}
              path={path}
              icon={icon}
            />
          )
        )}
      </List>
    </div>
  );

  //   const container = window !== undefined ? () => window().document.body : undefined;
  const container = document.body;

  return (
    <div className={`${classes.root} dashboard`}>
      <CssBaseline />
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <FontAwesomeIcon icon={faBars} size="2x" color="#2125BA"/>
          </IconButton>
          <div className="d-flex justify-content-between w-100">
            <Header />
          </div>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <StudentDetails />
        {children}
        <Footer />
      </main>
    </div>
  );
};

export default Sidebar;
