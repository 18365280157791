//utility function will go here
export const numberWithCommas = (x) => {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else return x;
};

export const urlToString = (url) => {
  return url
    .split("/")
    .join("")
    .split(":")
    .join("")
    .split(".")
    .join()
    .split(",")
    .join("");
};

export const isValuesEmpty = (values) => {
  const keysOfValus = Object.values(values);

  if (!(Object.keys(values).length > 0)) return true;
  let isEmpty = false;

  keysOfValus.forEach((value) => {
    if (!value) {
      isEmpty = true;
    }
  });

  return isEmpty;
};

export const isObjectEmpty = (value = {}) => {
  return !(Object.keys(value).length > 0);
};

export const deleteCity = async () => {
  await localStorage.removeItem("city");
};

export const saveCity = async (city) => {
  if (city) {
    const preCity = await localStorage.getItem("city");
    if (preCity) {
      await deleteCity();
      await localStorage.setItem("city", city);
    } else {
      await localStorage.setItem("city", city);
    }
  }
};

export const getCity = async () => {
  const city = await localStorage.getItem("city");
  if (!city) {
    return false;
  }
  return city;
};

export const deleteCustomerData = async () => {
  await localStorage.removeItem("studentData");
};

export const saveCustomerData = async (studentData) => {
  if (studentData) {
    const preCustomerData = await localStorage.getItem("studentData");
    if (!JSON.parse(preCustomerData)) {
      await localStorage.setItem("studentData", JSON.stringify(studentData));
    } else {
      await deleteCustomerData();
      await localStorage.setItem("studentData", JSON.stringify(studentData));
    }
  }
};

export const getCustomerData = async () => {
  const studentData = await localStorage.getItem("studentData");
  if (!JSON.parse(studentData)) {
    return false;
  }

  return JSON.parse(studentData);
};

// normalized image array

export const getImagesInCollumn = (imgs) => {
  if (!imgs[0]) return false;
  const columns = {};

  let localIndex = 0;
  let columnNumber = 0;

  imgs.forEach((item, index) => {
    if (localIndex < 3) {
      if (columns[columnNumber]) {
        columns[columnNumber] = [...columns[columnNumber], item];
      } else columns[columnNumber] = [item];

      localIndex = localIndex + 1;
      columnNumber = columnNumber + 1;
    } else {
      localIndex = 0;
      columnNumber = 0;
    }
  });

  return columns;
};

export const authHeader = () => {
  const authToken = localStorage.getItem("authToken");

  if (authToken) {
    return {
      Authorization: authToken,
    };
  }

  return {};
};

const numbers = {
  0: "০",
  1: "১",
  2: "২",
  3: "৩",
  4: "৪",
  5: "৫",
  6: "৬",
  7: "৭",
  8: "৮",
  9: "৯",
};

export const replaceNumbers = (input) => {
  if (!input) {
    // return 0;
    return;
  }
  var output = [];
  for (var i = 0; i < input.length; ++i) {
    if (numbers.hasOwnProperty(input[i])) {
      output.push(numbers[input[i]]);
    } else {
      output.push(input[i]);
    }
  }
  return output.join("");
};

export const convertParamsId = (params) => {
  if (Object.keys(params).length > 0) {
    return params.map((param) => Object.values(param).join());
  } else {
    return [];
  }
};

export function isEmpty(value) {
  value = `${value}`;
  const emptyValues = ["undefined", "null", "true", "false", "", "[]", "{}"];
  return emptyValues.includes(value);
}

//regex for email validation
export function validateEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

//regex for phone validation
export function validatePhone(phone) {
  var re = /^(?:\+88|01)?(?:\d{11}|\d{13})$/gm;
  return re.test(phone);
}

export const addFilterToStorage = (opt, cb) => {
  let filter =
    localStorage.filter && localStorage.filter.length > 0
      ? JSON.parse(localStorage.filter)
      : [];

  filter = [...filter, opt];

  localStorage.setItem("filter", JSON.stringify(filter));
  cb();
};
