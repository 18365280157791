import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import AuthBanner from "../../components/AuthComponent/AuthComponent";
import { Row } from "react-bootstrap";
import { Checkbox } from "@material-ui/core";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from "@fortawesome/free-regular-svg-icons";

const Login = () => {
  let status = true;
  var passw = /^(?=.*\d)(?=.*[a-zA-Z]).{8,16}$/;
  const validationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    password: yup
      .string("Enter your password")
      .matches(
        passw,
        "Use 8 or more characters with a mix of letters, numbers & symbols"
      )
      .required("Password is required"),
    number: yup
      .number("Enter the validate number")
      .required("Validate Number is required"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      number: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
      alert(JSON.stringify(values, null, 2));
      values.reset();
    },
  });
  const rand1 = Math.floor(Math.random() * 10);
  const rand2 = Math.floor(Math.random() * 10);
  const [randValidNum] = useState({
    rand1,
    rand2,
  });
  if (
    formik.values.number &&
    randValidNum.rand1 + randValidNum.rand2 === formik.values.number
  ){
    status = false;
  }

  return (
    <AuthBanner>
      <div className="content">
        <h1>Sign In</h1>
        <p>Welcome To Portal</p>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            id="email"
            name="email"
            label={
              <>
                Email <span>*</span>
              </>
            }
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            variant="outlined"
          />
          <TextField
            fullWidth
            id="password"
            name="password"
            label={
              <>
                Password <span>*</span>
              </>
            }
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            variant="outlined"
          />
          <TextField
            fullWidth
            id="number"
            name="number"
            label={
              <>
                {randValidNum.rand1} + {randValidNum.rand2} <span>*</span>
              </>
            }
            type="number"
            value={formik.values.number}
            onChange={formik.handleChange}
            error={formik.touched.number && Boolean(formik.errors.number)}
            helperText={formik.touched.number && formik.errors.number}
            variant="outlined"
          />
          <Button
            disabled={status}
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
          >
            <FontAwesomeIcon icon={faUser} size="lg" /> &nbsp; LOGIN
          </Button>

          <Row className="align-items-center pb-0">
            <FormControlLabel
              value="end"
              control={<Checkbox color="primary" />}
              label="Keep me Login"
              labelPlacement="end"
            />
            <Link>Forgot Password?</Link>
          </Row>
          <div className="m-0 p-0">
            <Row className="m-0 p-0">
              <p className="m-0 p-0">Don't have an account?</p>
              <p>
                <Link to="/signup">Sign Up</Link> now!
              </p>
            </Row>
          </div>
        </form>
      </div>
    </AuthBanner>
  );
};

export default Login;
