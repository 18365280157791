import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import * as reducers from "./ducs";
import { apiService } from "./middlewares";

const rootReducer = combineReducers(reducers);

const persistConfig = {
  key: "student",
  storage: storage,
  whitelist: ["lingual", "item"], // session removed from array
};

const pReducer = persistReducer(persistConfig, rootReducer);

function configureStore(initialState) {
  return createStore(
    pReducer,
    initialState,
    compose(
      applyMiddleware(apiService),
      window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : (f) => f
    )
  );
}

const store = configureStore({});

const persistor = persistStore(store);

export { persistor, store };
