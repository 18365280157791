import { useQuery } from "react-query";
import Connector from "../lib/connector.js";

const connector = new Connector();

const fetchFunction = async (item, options = {}) => {
  return connector.request(item, "json", options);
};

const useQueryFetch = (item, options, key, anotherKey) => {
  const { isLoading, isError, data, error, isSuccess, refetch } = useQuery(
    [key || item, anotherKey],
    () => fetchFunction(item, options),
    {
      retry: 0,
      refetchOnWindowFocus: false,
    }
  );

  return {
    isLoading,
    isError,
    data,
    error,
    isSuccess,
    refetch,
  };
};

export default useQueryFetch;
