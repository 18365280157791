import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import StudentPortalDatatable from "../../components/StudentPortalDataTable/StudentPortalDatatable";
import TableSm from "../../components/TableSm/TableSm";

import Layout from "../../hoc/Layout/Layout";

const data = [
  {
    subjectName: "Bangla 1st",
    subjectType: "compulsary",
  },
  {
    subjectName: "Bangla 2nd",
    subjectType: "compulsary",
  },
  {
    subjectName: "English 1st",
    subjectType: "compulsary",
  },
  {
    subjectName: "English 2nd",
    subjectType: "compulsary",
  },
  {
    subjectName: "Mathematics",
    subjectType: "compulsary",
  },
  {
    subjectName: "Physics",
    subjectType: "compulsary",
  },
  {
    subjectName: "Chemistry",
    subjectType: "compulsary",
  },
  {
    subjectName: "Higher Math",
    subjectType: "Fourth",
  },
  {
    subjectName: "Biology",
    subjectType: "Fourth",
  },
  {
    subjectName: "General Knowledge",
    subjectType: "Uncountable",
  },
];
const columns = [
  {
    field: "subjectName",
    header: "Subject Name",
  },
  {
    field: "subjectType",
    header: "Subject Type",
  },
];
const Subject = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  window.addEventListener("resize", () => {
    setWindowSize(window.innerWidth);
  });
  return (
    <Layout>
      {windowSize >= 641 ? (
        <StudentPortalDatatable
          columns={columns}
          value={data}
          title="Class Test Marks List"
        />
      ) : (
        <>
          <Row className="p-3 mb-1 header w-100 m-auto">
            <Col className="p-0">Subject List</Col>
            <Col className="text-right p-0">Total Found: {data.length}</Col>
          </Row>
          {data.map((data) => (
            <TableSm data={data} columns={columns} header={data.subjectName} />
          ))}
        </>
      )}
    </Layout>
  );
};

export default Subject;
