import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { NavLink, useHistory } from 'react-router-dom';


const SubMenu = ({item, icon, children, showSubMenu, setShowSubMenu}) => {
    const history = useHistory();
    const [flag, setFlag] = useState(true);

    const getActiveSubMenu = () => {
        if(history.location.pathname === children[0].path || history.location.pathname === children[1].path){
            flag && setShowSubMenu(item);
            setFlag(false);
        }
    }

    useEffect( getActiveSubMenu, [getActiveSubMenu]);

    const handleShowSubMenu = data => {
        console.log(data);
        setShowSubMenu(showSubMenu === item ? null : data);
    }
    return (
        <div className={showSubMenu === item ? 'sub-menu active' : 'sub-menu'}>
            <ListItem button key={item} onClick={() => handleShowSubMenu(item)}>
                <ListItemIcon>
                    <FontAwesomeIcon icon={icon} />
                </ListItemIcon>
                <ListItemText primary={item} />
                {
                children.length > 0 &&
                <ListItemIcon >
                    <FontAwesomeIcon icon={showSubMenu === item ? faChevronUp : faChevronDown} />
                </ListItemIcon>
                }
            </ListItem>
            {
            showSubMenu === item && children.map((subMenu) =>
                        <NavLink to={subMenu.path}>
                            <ListItem button key={subMenu.item} onClick={() => {history.push(subMenu.path); setShowSubMenu(item);}}>
                                <ListItemIcon>
                                    <div className="sub-menu-item-icon"></div>
                                    {/* <FontAwesomeIcon icon={faCircle} /> */}
                                </ListItemIcon>
                                <ListItemText primary={subMenu.item} />
                            </ListItem>
                        </NavLink>
            ) 
            }
        </div>
    );
};

export default SubMenu;