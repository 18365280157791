import React from "react";

const Footer = () => {
  return (
    <div className="footer-container">
      <p>
        Copyright &#169; 2024 <span>TechKnowGram LIMITED</span> All rights
        reserved
      </p>
    </div>
  );
};

export default Footer;
