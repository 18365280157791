import React from "react";
import { connect } from "react-redux";
import Sidebar from "../../layout/Sidebar";
import Auxiliary from "../Auxiliary/Auxiliary";

const Layout = ({ children }) => {
  return (
    <Auxiliary style={{ backgroundColor: "red" }}>
      <Sidebar>{children}</Sidebar>
      {/* <main className="content">{this.props.children}</main> */}
    </Auxiliary>
  );
};

const mapStateToProps = (state) => {
  return {
    // isAuthenticated: state.auth.token !== null
  };
};

export default connect(mapStateToProps)(Layout);
