import React from "react";
import Layout from "../../hoc/Layout/Layout";
import ProfileDataTable from "./ProfileDataTable";

const Profile = () => {
  const basicInfo = [
    {
      title: "Gender",
      value: "Male",
    },
    {
      title: "Date of Birth",
      value: "4th September, 1989",
    },
    {
      title: "Email",
      value: "farhan@gmail.com",
    },
    {
      title: "Religion",
      value: "Islam",
    },
    {
      title: "Mobile No.",
      value: "01672242158",
    },
    {
      title: "Birth Certificate No.",
      value: "44434345665654",
    },
  ];

  const parentInfo = [
    {
      title: "Father's Name",
      value: "Mosaddek Ahmed",
    },
    {
      title: "Father's NID",
      value: "6544981271354",
    },
    {
      title: "Guardian Mobile No.",
      value: "01712345678",
    },
    {
      title: "Mother's Name",
      value: "Sania Ahmed",
    },
    {
      title: "Mother's NID",
      value: "6544981271354",
    },
    {
      title: "Guardian Email",
      value: "ahmed@gmail.com",
    },
  ];

  const previousInstituteInfo = [
    {
      title: "Institute Name",
      value: "TechKnowGram School and Collage",
    },
    {
      title: "Contact No.",
      value: "01712345678",
    },
    {
      title: "Time Period",
      value: "2017-2018",
    },
    {
      title: "Address",
      value: "Mirpur DOHS",
    },
    {
      title: "Email",
      value: "TechKnowGram@gmail.com",
    },
    {
      title: "Last Education",
      value: "Class 2",
    },
  ];

  const addressInfo = {
    presentAddress: "Flat 07, House 2, Road 3, Kolabagan, Dhaka - 1214",
    permanentAddress: "Flat 07, House 2, Road 3, Kolabagan, Dhaka - 1214",
  };

  return (
    <Layout>
      <ProfileDataTable data={basicInfo} title="Basic Info" />
      <ProfileDataTable data={parentInfo} title="Parent Info" />
      <ProfileDataTable
        data={previousInstituteInfo}
        title="Previous Institute Info"
      />

      <div className="row custom-table mb-4 mx-0">
        <div className="col-12 table-title">Address Info</div>
        <div className="col-md-6">
          <div className="row">
            <div className="col-5 data-title">Present Address</div>
            <div className="col-7 data-value">{addressInfo.presentAddress}</div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="row">
            <div className="col-5 data-title">Permanent Address</div>
            <div className="col-7 data-value">
              {addressInfo.permanentAddress}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Profile;
