import React from "react";
import { Col, Row } from "reactstrap";
import student from "../asset/images/student.jpeg";

const StudentDetails = () => {
  return (
    <div className="student-container align-items-center mb-5 mt-2">
      <Row className="align-items-center m-0">
        <Col lg={5}>
          <Row className="align-items-center  justify-content-around m-0">
            <Col sm={4} xs={12} className="p-0">
              <div
                style={{ backgroundImage: `url(${student})` }}
                className="profile-pic"
              />
            </Col>
            <div className="student-details col-sm-8 p-0">
              <p>
                <span>Student Id</span>
                <span>: 1234545</span>
              </p>
              <p>
                <span>Roll No</span>
                <span>: 1</span>
              </p>
              <p>
                <span>Academic Year</span>
                <span>: 2021</span>
              </p>
              <span></span>
            </div>
          </Row>
        </Col>
        <Col lg={3} sm={5} className="student-class-details">
          <p>
            <span>Class</span>
            <span>: nine</span>
          </p>
          <p>
            <span>Shift</span>
            <span>: Morning</span>
          </p>
          <p>
            <span>Section</span>
            <span>: Butterfly</span>
          </p>
          <p>
            <span>Group</span>
            <span>: Science</span>
          </p>
          <span></span>
        </Col>
        <Col lg={4} sm={7}>
          <div className="student-institute-details">
            <p>
              <span>Institute Id</span>
            </p>
            <p>12345</p>
            <p>
              <span>Institute Name</span>{" "}
            </p>
            <p>TechKnowGram IT High School & College </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default StudentDetails;
