import React from "react";

const ProfileDataTable = ({ title, data }) => {
  return (
    <div className="row custom-table mb-4 mx-0">
      <div className="col-12 table-title">{title}</div>

      {data.map((info) => (
        <div className="col-lg-4 col-md-6">
          <div className="row">
            <div className="col-5 data-title">{info.title}</div>
            <div className="col-7 data-value">{info.value}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProfileDataTable;
