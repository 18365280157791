class Converter {
  /**
   * @public
   * @method postList convert api data from API to general format
   * @param {Object} data response objectc from wc
   * @returns {Object}  converted data
   */
  async postList(resData) {
    const data = resData;
    let convertedData =
      data.length > 0 &&
      data.map((post) => {
        return {
          ...post,
          id: post.id,
          title: post.title,
          body: post.body,
          userId: post.userId,
        };
      });

    return convertedData;
  }

  /**
   * @method to convert the api response data
   * @param {*} resData
   * @returns converted resData
   */

  postDetail(resData) {
    return resData;
  }
}

export default Converter;
