import React, { useState } from "react";
import AuthBanner from "../../components/AuthComponent/AuthComponent";
import * as yup from "yup";
import { useFormik } from "formik";
import TextField from "@material-ui/core/TextField";
import { IconButton, InputAdornment } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import copy from "copy-to-clipboard";
import Button from "@material-ui/core/Button";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";

const Registration = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [values, setValues] = useState({
    password: false,
    confirmPassword: false,
  });
  const handleClickShowPassword = () => {
    setValues({
      password: !values.password,
    });
  };
  const handleClickShowConfirmPassword = () => {
    setValues({ confirmPassword: !values.confirmPassword });
  };
  var passw = /^(?=.*\d)(?=.*[a-zA-Z]).{8,16}$/;
  const validationSchema = yup.object({
    password: yup
      .string("Enter your password")
      .matches(
        passw,
        "Use 8 or more characters with a mix of letters, numbers & symbols"
      )
      .required("Password is required"),
    confirmPassword: yup
      .string("Enter the validate number")
      .required("Validate Number is required"),
  });
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      if (values.password === values.confirmPassword) {
        setErrorMessage("");
        console.log(values);
        alert(JSON.stringify(values, null, 2));
        resetForm({});
      } else {
        setErrorMessage("password and confirm password is not matched");
      }
    },
  });
  const onCopy = () => {
    copy("ijiuj");
  };
  return (
    <AuthBanner>
      <div className="content">
        <h1>registration</h1>
        <p>Registered To Your Student Portal</p>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            id="username"
            name="username"
            label={
              <>
                UserName <span>*</span>
              </>
            }
            value="123456"
            type="text"
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <FontAwesomeIcon onClick={onCopy} icon={faCopy} size="lg" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            variant="outlined"
          />
          <TextField
            fullWidth
            id="password"
            name="password"
            label={
              <>
                Password <span>*</span>
              </>
            }
            type={values.password ? "text" : "password"}
            value={formik.values.password}
            onChange={formik.handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {values.password ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            variant="outlined"
          />
          <TextField
            fullWidth
            id="confirmPassword"
            name="confirmPassword"
            label={
              <>
                Confirm Password <span>*</span>
              </>
            }
            type={values.confirmPassword ? "text" : "password"}
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    //   onMouseDown={handleMouseDownPassword}
                  >
                    {values.confirmPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
            variant="outlined"
          />
          {errorMessage ? (
            <span style={{ color: "red" }}>{errorMessage}</span>
          ) : (
            <></>
          )}
          <Button color="primary" variant="contained" fullWidth type="submit">
            <FontAwesomeIcon icon={faSignInAlt} size="lg" /> &nbsp; register
          </Button>
        </form>
      </div>
    </AuthBanner>
  );
};

export default Registration;
