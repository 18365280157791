import React from 'react';

const RectangleComponent = ({title = "...", value = 0}) => {
    return (
        <div className="rectangle">
            <div className="circle">
                <h2>{value}</h2>
            </div>
            <p>{title}</p>
        </div>
    );
};

export default RectangleComponent;