import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Select from "react-select";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import RequiredSign from "../../components/RequiredSign";
import StudentPortalDatatable from "../../components/StudentPortalDataTable/StudentPortalDatatable";
import Layout from "../../hoc/Layout/Layout";
import useQueryFetch from "../../hooks/useQueryFetch";
import TableSm from "../../components/TableSm/TableSm";
import { Col, Row } from "react-bootstrap";

const ExamRoutine = () => {
  const routine = useQueryFetch("postList");
  console.log({ routine });
  const years = [];
  const data = [
    {
      dayanddate: "20-02-2020",
      day: "Staurday",
      time: "11 am - 12 pm",
      subject: "bangla",
      roomno: "101",
    },
    {
      dayanddate: "20-02-2020",
      day: "Sunday",
      time: "11 am - 12 pm",
      subject: "bangla",
      roomno: "101",
    },
    {
      dayanddate: "20-02-2020",
      day: "Monday",
      time: "11 am - 12 pm",
      subject: "bangla",
      roomno: "101",
    },
    {
      dayanddate: "20-02-2020",
      day: "Tuesday",
      time: "11 am - 12 pm",
      subject: "bangla",
      roomno: "101",
    },
    {
      dayanddate: "20-02-2020",
      day: "Wednesday",
      time: "11 am - 12 pm",
      subject: "bangla",
      roomno: "101",
    },
    {
      dayanddate: "20-02-2020",
      day: "Thursday",
      time: "11 am - 12 pm",
      subject: "bangla",
      roomno: "101",
    },
  ];
  const columns = [
    {
      field: "dayanddate",
      header: "Date",
    },
    {
      field: "time",
      header: "Time",
    },
    {
      field: "subject",
      header: "Subject",
    },
    {
      field: "roomno",
      header: "Room No",
    },
  ];
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  window.addEventListener("resize", () => {
    setWindowSize(window.innerWidth);
  });
  return (
    <Layout>
      <div className="row mt-3 mb-4">
        <div className="col-md-3">
          <label className="input-label">
            Session <RequiredSign />
          </label>
          <Select options={years} placeholder="Select Session" />
        </div>
        <div className="col-md-3">
          <label className="input-label">
            Exam <RequiredSign />
          </label>
          <Select options={years} placeholder="Select Exam" />
        </div>
        <div className="col-md-3 d-flex align-items-end">
          <button className="search-btn">
            Search &nbsp; <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>
      </div>
      {windowSize >= 641 ? (
        <StudentPortalDatatable columns={columns} value={data} />
      ) : (
        <>
          <Row className="p-3 mb-1 header w-100 m-auto">
            <Col className="p-0">Exam Routine</Col>
            <Col className="text-right p-0">Total Found: {data.length}</Col>
          </Row>
          {data.map((data) => (
            <TableSm data={data} columns={columns} header={data.day} />
          ))}
        </>
      )}
    </Layout>
  );
};

export default ExamRoutine;
