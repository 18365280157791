import React, {useState} from 'react';
import AuthBanner from "../../components/AuthComponent/AuthComponent";
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';
import OtpInput from 'react-otp-input';


const Verification = ({phoneNumber = "01794661766"}) => {
    const [otpCodeInput, setOtpCodeInput] = useState('');
    let displayNumber = phoneNumber.split("");
    displayNumber.splice(3, 6, "*", "*", "*", "*", "*", "*");

    return (
        <AuthBanner>
            <div className="content">
                <h1>Verification</h1>
                <p>A 6 digit verification code has been sent to your registered {displayNumber.join("")} mobile numbers.</p>
                <section className="otp">
                    <h3>otp code</h3>
                    <div className="otp-input">
                        <OtpInput
                            value={otpCodeInput}
                            onChange={otp => setOtpCodeInput(otp)}
                            numInputs={6}
                        />
                    </div>
                    <p>I didn't get any code, <span>RESEND</span></p>
                </section>
                <Button className="verification-btn" color="primary" variant="contained" fullWidth type="submit" onClick={() => console.log(parseInt(otpCodeInput))}>
                    <FontAwesomeIcon icon={faCheck} size="lg" /> &nbsp; Verify
                </Button>
            </div>
        </AuthBanner>
    );
};

export default Verification;