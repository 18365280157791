import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import StudentPortalDatatable from "../../components/StudentPortalDataTable/StudentPortalDatatable";
import TableSm from "../../components/TableSm/TableSm";
import FeesAndInventory from "../../components/feesAndInventoryCommon/FeesAndInventory";
import Layout from "../../hoc/Layout/Layout";

const FeesInfo = () => {
  const data = [
    {
      invoiceId: "1910597000350",
      paymentDat: "2024-09-25",
      feeHead: "tuition Fees, Transport Fees",
      feeSubHead: "payableAmount",
      payableAmount: "1000",
      paidAmount: "1000",
      due: "000",
    },
    {
      invoiceId: "1910597000350",
      paymentDat: "2024-09-25",
      feeHead: "tuition Fees, Transport Fees",
      feeSubHead: "payableAmount",
      payableAmount: "1000",
      paidAmount: "1000",
      due: "000",
    },
    {
      invoiceId: "1910597000350",
      paymentDat: "2024-09-25",
      feeHead: "tuition Fees, Transport Fees",
      feeSubHead: "payableAmount",
      payableAmount: "1000",
      paidAmount: "1000",
      due: "000",
    },
    {
      invoiceId: "1910597000350",
      paymentDat: "2024-09-25",
      feeHead: "tuition Fees, Transport Fees",
      feeSubHead: "payableAmount",
      payableAmount: "1000",
      paidAmount: "1000",
      due: "000",
    },
    {
      invoiceId: "1910597000350",
      paymentDat: "2024-09-25",
      feeHead: "tuition Fees, Transport Fees",
      feeSubHead: "payableAmount",
      payableAmount: "1000",
      paidAmount: "1000",
      due: "000",
    },
  ];

  const columns = [
    {
      field: "invoiceId",
      header: "Invoice Id",
    },
    {
      field: "paymentDat",
      header: "Payment Date",
    },
    {
      field: "feeHead",
      header: "Fees Head",
    },
    {
      field: "feeSubHead",
      header: "Fees Sub Head",
    },
    {
      field: "payableAmount",
      header: "Payable Amount",
    },
    {
      field: "paidAmount",
      header: "Pay Amount",
    },
    {
      field: "due",
      header: "Due",
    },
  ];
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  window.addEventListener("resize", () => {
    setWindowSize(window.innerWidth);
  });
  return (
    <Layout>
      <FeesAndInventory />
      {windowSize >= 641 ? (
        <StudentPortalDatatable
          columns={columns}
          value={data}
          title="Class Test Marks List"
        />
      ) : (
        <>
          <Row className="p-3 mb-1 header w-100 m-auto">
            <Col className="p-0">Paid List</Col>
            <Col className="text-right p-0">Total Found: {data.length}</Col>
          </Row>
          {data.map((data) => (
            <TableSm data={data} columns={columns} header={data.invoiceId} />
          ))}
        </>
      )}
    </Layout>
  );
};

export default FeesInfo;
