import React, { useState } from "react";
import Select from "react-select";
import RequiredSign from "../RequiredSign";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

const AcademicYear = () => {
  const [isDisabled, setIsDisabled] = useState(true);
  const date = new Date();
  const options = [];

  for (let value = date.getFullYear(); value >= 2015; value--) {
    options.push({ value, label: value });
  }

  function handleChange({ value }) {
    console.log(value);
  }

  function toggleDisable() {
    setIsDisabled(!isDisabled);
  }

  return (
    <div className="col-xl-6">
      <div className="academicYearInput">
        <label className="input-label">
          Academic Year <RequiredSign />{" "}
        </label>
        <div className="row">
          <div className="col-md-6">
            <Select
              isDisabled={isDisabled}
              options={options}
              placeholder="Select Academic Year"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6">
            <button onClick={toggleDisable}>
              Academic Year &nbsp;
              <FontAwesomeIcon icon={faFilter} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcademicYear;
