import React from 'react';

const RoutineSM = ({routineData, periodList, allPeriod}) => {
    
    
    return (
        <div className="class-routine-table class-routine-table-sm">
            <table className="table">
                {
                    routineData.map(({dayName, periodCellViewer}) => <>
                    <thead>
                    <tr>
                        <th colSpan="2">{dayName}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        allPeriod.map(period => <tr>
                            <th>{periodList[period]}</th>
                            <td>{periodCellViewer[period][0]?.subjectName} <br/> <u>{periodCellViewer[period][0]?.teacherName}</u></td>
                        </tr>
                        )
                    }
                </tbody>
                    </>)
                }
                
            </table>
        </div>
    );
};

export default RoutineSM;