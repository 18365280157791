import React from "react";
// import { connect } from "react-redux";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  // withRouter,
} from "react-router-dom";
import AttendanceInfo from "./containers/attendanceInfo/AttendanceInfo";
import Login from "./containers/auth/Login";
import Registration from "./containers/auth/Registration";
import Signup from "./containers/auth/Signup";
import Verification from "./containers/auth/Verification";
import ClassRoutine from "./containers/classRoutine/ClassRoutine";
import ClassTest from "./containers/classTest/ClassTest";
import Dashboard from "./containers/dashboard";
import ExamRoutine from "./containers/examRoutine/ExamRoutine";
import FeesInfo from "./containers/feesInfo/FeesInfo";
import InventoryInfo from "./containers/inventoryInfo/InventoryInfo";
import Profile from "./containers/profile/Profile";
import SemesterExam from "./containers/semesterExam/SemesterExam";
import Subject from "./containers/subject/Subject";
// import LandingPage from "./containers/LandingPage";
// import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  let isAuthenticated = true;
  let routes = (
    <Switch>
      <Route path="/signup" component={Signup} />
      <Route path="/verification" component={Verification} />
      <Route path="/login" component={Login} />
      <Route path="/registration" component={Registration} />
      <Route path="/" exact component={Login} />
      <Redirect to="/" />
    </Switch>
  );

  // if (this.props.isAuthenticated) {
  if (isAuthenticated) {
    routes = (
      <Switch>
        <Route path="/" exact component={Dashboard} />
        <Route path="/profile" exact component={Profile} />
        <Route path="/attendance-info" exact component={AttendanceInfo} />
        <Route path="/subject-info" exact component={Subject} />
        <Route path="/routine-info/class-routine" exact component={ClassRoutine} />
        <Route path="/routine-info/exam-routine" exact component={ExamRoutine} />
        <Route path="/exam-info/semester-exam" exact component={SemesterExam} />
        <Route path="/exam-info/class-test" exact component={ClassTest} />
        <Route path="/fees-info" exact component={FeesInfo} />
        <Route path="/inventory-info" exact component={InventoryInfo} />
        <Redirect to="/" />
      </Switch>
    );
  }
  return (
    <BrowserRouter>
      <React.Fragment>{routes}</React.Fragment>
    </BrowserRouter>
  );
};

export default App;
