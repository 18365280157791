import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import StudentPortalDatatable from "../../components/StudentPortalDataTable/StudentPortalDatatable";
import TableSm from "../../components/TableSm/TableSm";
import FeesAndInventory from "../../components/feesAndInventoryCommon/FeesAndInventory";
import Layout from "../../hoc/Layout/Layout";

const InventoryInfo = () => {
  const data = [
    {
      purchaseDate: "2024-08-25",
      category: "Book",
      item: "English Book",
      unitPrice: "50",
      totalPrice: "500",
    },
    {
      purchaseDate: "2024-08-25",
      category: "Book",
      item: "English Book",
      unitPrice: "50",
      totalPrice: "500",
    },
    {
      purchaseDate: "2024-08-25",
      category: "Book",
      item: "English Book",
      unitPrice: "50",
      totalPrice: "500",
    },
    {
      purchaseDate: "2024-08-25",
      category: "Book",
      item: "English Book",
      unitPrice: "50",
      totalPrice: "500",
    },
    {
      purchaseDate: "2024-08-25",
      category: "Book",
      item: "English Book",
      unitPrice: "50",
      totalPrice: "500",
    },
  ];
  const columns = [
    {
      field: "purchaseDate",
      header: "Purchase Date",
    },
    {
      field: "category",
      header: "Category",
    },
    {
      field: "item",
      header: "Item",
    },
    {
      field: "unitPrice",
      header: "Unit Price",
    },
    {
      field: "quantity",
      header: "Quantity",
    },
    {
      field: "totalPrice",
      header: "Total Price",
    },
  ];
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  window.addEventListener("resize", () => {
    setWindowSize(window.innerWidth);
  });
  return (
    <Layout>
      <FeesAndInventory />
      {windowSize >= 641 ? (
        <StudentPortalDatatable
          columns={columns}
          value={data}
          title="Class Test Marks List"
        />
      ) : (
        <>
          <Row className="p-3 mb-1 header w-100 m-auto">
            <Col className="p-0">List Item</Col>
            <Col className="text-right p-0">Total Found: {data.length}</Col>
          </Row>
          {data.map((data) => (
            <TableSm data={data} columns={columns} header={data.item} />
          ))}
        </>
      )}
    </Layout>
  );
};

export default InventoryInfo;
