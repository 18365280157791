import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import RectangleComponent from "../../components/RectangleComponent";
import RequiredSign from "../../components/RequiredSign";
import StudentPortalDatatable from "../../components/StudentPortalDataTable/StudentPortalDatatable";
import TableSm from "../../components/TableSm/TableSm";
import AcademicYear from "../../components/inputs/AcademicYear";
import Layout from "../../hoc/Layout/Layout";

const AttendanceInfo = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  window.addEventListener("resize", () => {
    setWindowSize(window.innerWidth);
  });
  const date = new Date();
  const years = [];
  const month = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];
  const period = [
    { value: 1, label: "1st Period" },
    { value: 2, label: "2nd Period" },
    { value: 3, label: "3rd Period" },
    { value: 4, label: "4th Period" },
    { value: 5, label: "5th Period" },
    { value: 6, label: "6th Period" },
    { value: 7, label: "7th Period" },
    { value: 8, label: "8th Period" },
    { value: 9, label: "9th Period" },
    { value: 10, label: "10th Period" },
  ];

  const data = useState([
    {
      date: "20-04-2024",
      day: "sunday",
      status: "Present",
    },
    {
      date: "22-04-2024",
      day: "sunday",
      status: "Present",
    },
    {
      date: "21-03-2024",
      day: "sunday",
      status: "Present",
    },
    {
      date: "21-03-2024",
      day: "sunday",
      status: "Present",
    },
  ]);

  const columns = [
    {
      field: "date",
      header: "Date",
    },
    {
      field: "day",
      header: "Day",
    },
    {
      field: "status",
      header: "Status",
    },
  ];

  for (let value = date.getFullYear(); value >= 2015; value--) {
    years.push({ value, label: value });
  }
  console.log(data[0]);
  return (
    <Layout>
      <div className="row">
        <AcademicYear />
      </div>
      <div className="row mt-3">
        <div className="col-md-3">
          <label className="input-label">
            Year <RequiredSign />{" "}
          </label>
          <Select options={years} placeholder="Select Year" />
        </div>
        <div className="col-md-3">
          <label className="input-label">
            Period <RequiredSign />{" "}
          </label>
          <Select options={period} placeholder="Select Period" />
        </div>
        <div className="col-md-3">
          <label className="input-label">
            Month <RequiredSign />{" "}
          </label>
          <Select options={month} placeholder="Select Month" />
        </div>
        <div className="col-md-3 d-flex align-items-end">
          <button className="search-btn">
            Search &nbsp; <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-lg-3 col-sm-6">
          <RectangleComponent title="working days" value="06" />
        </div>
        <div className="col-lg-3 col-sm-6">
          <RectangleComponent title="off days" value="01" />
        </div>
        <div className="col-lg-3 col-sm-6">
          <RectangleComponent title="total present" value="05" />
        </div>
        <div className="col-lg-3 col-sm-6">
          <RectangleComponent title="total absent" value="01" />
        </div>
      </div>

      <div className="mt-4">
        {windowSize >= 641 ? (
          <StudentPortalDatatable columns={columns} value={data[0]} />
        ) : (
          <>
            <Row className="p-3 mb-1 header w-100 m-auto">
              <Col className="p-0">Attendance info</Col>
              <Col className="text-right p-0">
                Total Found: {data[0].length}
              </Col>
            </Row>
            {data[0].map((data) => (
              <TableSm data={data} columns={columns} header={data.day} />
            ))}
          </>
        )}
      </div>
    </Layout>
  );
};

export default AttendanceInfo;
