import React from 'react';

const RoutineMD = ({routineData, periodList, allPeriod, periodTime}) => {
    return (
        <div className="class-routine-table class-routine-table-md">
            <table className="table">
                <thead>
                    <tr>
                        <th rowSpan="2" className="text-center table-colored">Day <br/> & <br/>Time</th>
                        {
                            allPeriod.map(period => <th>{periodList[period]}</th>)
                        }
                    </tr>
                    <tr className="table-colored">
                        {
                            periodTime.map(time => <td>{time} <br/> (Duration = 40 min) </td>)
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        routineData.map(({dayName, periodCellViewer}) => <tr>
                        <td>{dayName}</td>
                        {
                            allPeriod.map(period => <td>{periodCellViewer[period][0]?.subjectName} <br/> <u>{periodCellViewer[period][0]?.teacherName}</u></td>)
                        }
                    </tr>)
                    }
                    {/* <tr>
                        <td>Saturday</td>
                        <td>Mathematics dfgjkjfdjglk lkdfj lkdfjgkl kfdjglkdfs jgkldfjg  <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                    </tr>
                    <tr>
                        <td>Sunday</td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                    </tr>
                    <tr>
                        <td>Monday</td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                    </tr>
                    <tr>
                        <td>Tuesday</td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                    </tr>
                    <tr>
                        <td>Wednesday</td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                    </tr>
                    <tr>
                        <td>Thursday</td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                        <td>Mathematics <br/> <u>M.R</u></td>
                    </tr> */}
                </tbody>
            </table>
        </div>
    );
};

export default RoutineMD;