import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Exam from "../../components/examCommon/Exam";
import StudentPortalDatatable from "../../components/StudentPortalDataTable/StudentPortalDatatable";
import TableSm from "../../components/TableSm/TableSm";
import Layout from "../../hoc/Layout/Layout";
const SemesterExam = () => {
  const data = [
    {
      subjectName: "English 1st",
      fullMarks: "100",
      highestNumber: "90",
      obtainMarks: "80",
      latterGrade: "A",
      gradPoint: "4",
    },
    {
      subjectName: "English 1st",
      fullMarks: "100",
      highestNumber: "90",
      obtainMarks: "80",
      latterGrade: "A",
      gradPoint: "4",
    },
    {
      subjectName: "English 1st",
      fullMarks: "100",
      highestNumber: "90",
      obtainMarks: "80",
      latterGrade: "A",
      gradPoint: "4",
    },
    {
      subjectName: "English 1st",
      fullMarks: "100",
      highestNumber: "90",
      obtainMarks: "80",
      latterGrade: "A",
      gradPoint: "4",
    },
    {
      subjectName: "English 1st",
      fullMarks: "100",
      highestNumber: "90",
      obtainMarks: "80",
      latterGrade: "A",
      gradPoint: "4",
    },
  ];
  const columns = [
    { field: "subjectName", header: "Subject Name" },
    { field: "fullMarks", header: "full Marks" },
    { field: "highestNumber", header: "Highest Marks" },
    { field: "obtainMarks", header: "Obtain Marks" },
    { field: "latterGrade", header: "latterGrade" },
    { field: "gradPoint", header: "Grade Point" },
  ];
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  window.addEventListener("resize", () => {
    setWindowSize(window.innerWidth);
  });
  return (
    <Layout>
      <Exam />
      {windowSize >= 641 ? (
        <StudentPortalDatatable
          columns={columns}
          value={data}
          title="Semester Exam Result"
        />
      ) : (
        <>
          <Row
            className="p-3 mb-1 header w-100 m-auto"
            style={{ fontSize: "14px" }}
          >
            <Col className="p-0">Semester Exam Result</Col>
            <Col className="text-right p-0">Total Found: {data.length}</Col>
          </Row>
          {data.map((data) => (
            <TableSm data={data} columns={columns} header={data.subjectName} />
          ))}
        </>
      )}
    </Layout>
  );
};

export default SemesterExam;
