import React from "react";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import AcademicYear from "../inputs/AcademicYear";
import RequiredSign from "../RequiredSign";
const FeesAndInventory = () => {
  const years = [];
  return (
    <div className="exam">
      <div className="row">
        <AcademicYear />
        <div className="col-xl-6">
          <label className="input-label">
            Month <RequiredSign />
          </label>
          <div className="row">
            <div className="col-md-6">
              <Select options={years} placeholder="Select Month" />
            </div>
            <div className="col-md-6">
              <button className="search-btn" style={{ minWidth: "155px" }}>
                Search &nbsp; <FontAwesomeIcon icon={faSearch} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeesAndInventory;
