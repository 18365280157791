import AuthBanner from "../../components/AuthComponent/AuthComponent";
import React, { useState } from "react";
import { Button, FormControl, Select, MenuItem, InputLabel, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';


const Signup = () => {

  const [role, setRole] = useState(null);
  const [rolePlaceHolder, setRolePlaceHolder] = useState("I am a");
  const [error, setError] = useState(false);
  
  const validationSchema = yup.object({
    userId: yup
      .number()
      .required('Id is required'),
  });


  const formik = useFormik({
    initialValues: {
      userId: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if(role){
        alert(JSON.stringify({...values, role}, null, 2));
      } else{
        setError(true);
      }
    },
  });


  return (
  <AuthBanner>
    <div className="content">
      <h1>Sign Up</h1>
      <p>Welcome To Student Portal</p>
      
      <form onSubmit={formik.handleSubmit}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="demo-simple-select-outlined-label">{rolePlaceHolder}</InputLabel>
          <Select
            // required
            labelId="demo-simple-select-outlined-label"
            id="role"
            value={role} 
            error={error}           
            onChange={(event) => {
              setRole(event.target.value);
              setRolePlaceHolder(<>Role <span>*</span></>);
              setError(false);
            }}
            label="Role *"
          >
            <MenuItem value={"Student"}>Student</MenuItem>
            <MenuItem value={"Guardian"}>Guardian</MenuItem>
            <MenuItem value={"Teacher"}>Teacher</MenuItem>
          </Select>
          {error && <p>Please Select Your Role</p>}
        </FormControl>

        <TextField
          fullWidth
          id="userId"
          name="userId"
          type="number"
          label={<>Student ID <span>*</span></>}
          value={formik.values.userId}
          onChange={formik.handleChange}
          error={formik.touched.userId && Boolean(formik.errors.userId)}
          variant="outlined"
          helperText={formik.touched.userId && formik.errors.userId}
        />

        <Button color="primary" variant="contained" fullWidth type="submit">
          <FontAwesomeIcon icon={faCheck} size="lg" /> &nbsp; Submit
        </Button>
        
      </form>
    </div>
  </AuthBanner>
  )
  ;
};

export default Signup;
