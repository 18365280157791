import React from "react";

const TableSm = (props) => {
  const { data, columns, header, date } = props;
  console.log(props);
  return (
    <div className="table-small">
      <table className="table ">
        <tr>
          <th colSpan="2" className="text-center header">
            {header} <br />
            {date}
          </th>
        </tr>
        {columns.map((column) => (
          <>
            {header === data[column.field] ? (
              <></>
            ) : (
              <tr>
                <th>{column.header}</th>
                <td>
                  {
                    data[
                      Object.keys(data).filter(
                        (filterData) => filterData === column.field
                      )
                    ]
                  }
                </td>
              </tr>
            )}
          </>
        ))}
      </table>
    </div>
  );
};

export default TableSm;
