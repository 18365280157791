/**
 * @method checks whether an object is empty or not
 * @param {*} value
 * @returns a boolean
 */
const isObjectEmpty = (value) => {
  return !value || !Object.keys(value).length > 0;
};

/**
 * @method converts the urlOptions object to api url
 * @param {*} url
 * @param {*} urlOptions is the object containing api url parameters
 * @returns the formatted api url
 */

const manipulateURL = (url, urlOptions = {}) => {
  if (isObjectEmpty(urlOptions)) return url;

  const params = urlOptions.params || {};
  const placeHolders = urlOptions.placeHolders || {};

  return url
    .split("/")
    .map((currentValue) => {
      if (
        currentValue &&
        currentValue.length &&
        currentValue.includes(":") &&
        !isObjectEmpty(placeHolders) &&
        !currentValue.includes("?")
      ) {
        let actualPlaceholderValue =
          placeHolders[currentValue.replace(":", "")];
        currentValue = actualPlaceholderValue;
      }

      if (
        currentValue &&
        currentValue.length &&
        currentValue.includes(":") &&
        !isObjectEmpty(placeHolders) &&
        currentValue.includes("?")
      ) {
        let value = currentValue.split("?");
        if (value && value.length > 0) {
          value = value.map((item) => {
            return placeHolders[item.replace(":", "")]
              ? placeHolders[item.replace(":", "")]
              : item;
          });
        }
        let actualPlaceholderValue = value.join("?");
        currentValue = actualPlaceholderValue;
      }

      if (currentValue && currentValue.length && currentValue.includes("?")) {
        currentValue = currentValue
          .split("&")
          .map((currentValue) => {
            const param = currentValue.slice(currentValue.indexOf("=") + 1);
            return currentValue.replace(param, params[param] || "");
          })
          .join("&");
      }
      return currentValue;
    })
    .join("/");
};

export default manipulateURL;
