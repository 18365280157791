import React from 'react';

const RequiredSign = () => {
    return (
        <span className="required-sign">
            *
        </span>
    );
};

export default RequiredSign;