import React from "react";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { Row, Col } from "react-bootstrap";
const StudentPortalDatatable = ({
  value,
  columns,
  title = "Subject List",
  paginator,
}) => {
  const dynamicColumns = columns.map((col, i) => {
    return (
      <Column
        key={col.field}
        sortable
        filter
        field={col.field}
        header={col.header}
      />
    );
  });
  return (
    <div className="subjectList-datatable">
      <div className="datatable-responsive-demo">
        <div className="card">
          <Row className="p-3 mb-1 header">
            <Col className="p-0">{title}</Col>
            <Col className="text-right p-0">Total Found: {value.length}</Col>
          </Row>
          <DataTable
            value={value}
            className="p-datatable-responsive-demo p-datatable-striped"
            paginator={paginator ? true : false}
            rows={10}
          >
            {dynamicColumns}
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default StudentPortalDatatable;
